@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@500;600;700&display=swap');

// font awesome
@import url('plugins/all.min.css');

// line awesome
@import url('plugins/line-awesome.min.css');

// variables
@import '_variables.scss';

// mixins
@import '_mixins.scss';

// default
@import '_default.scss';

// classes
@import '_classes.scss';

// navigation
@import '_navigation.scss';

// navigation
@import '_hero.scss';

// navigation
@import '_button.scss';

// section heading
@import '_section-heading.scss';

// app feature section
@import '_app-feature.scss';

// video section
@import '_video.scss';

// icon text section
@import '_icon-text.scss';

// pricing section
@import '_pricing.scss';

// testimonial section
@import '_testimonial.scss';

// faq section
@import '_faq.scss';

// blog
@import '_blog.scss';

// cta
@import '_cta.scss';

// tabs
@import '_tabs.scss';

// page header
@import '_page-header.scss';

// about
@import '_about.scss';

// team
@import '_team.scss';

// instagram
@import '_instagram.scss';

// contact
@import '_contact.scss';

// footer
@import '_footer.scss';

// preloader
@import '_preloader.scss';

// main
@import '_main.scss';

// screen section
@import '_screens.scss';

.brand-logo {
    width: 130px;
    height: 40px;
}
