@mixin animateIt($duration: 0.25s, $timing_func: ease-out, $delay: 0s) {
    -webkit-transition: all $duration $timing_func $delay;
    -moz-transition: all $duration $timing_func $delay;
    -o-transition: all $duration $timing_func $delay;
    transition: all $duration $timing_func $delay;
}

@mixin absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin line-anim-mixin($path-number, $offset){
    @keyframes line-anim-#{$path-number} {
      0% { stroke-dashoffset: $offset; }
      50% { stroke-dashoffset: 0; }
      100% { stroke-dashoffset: $offset; }
    }
}

@mixin border-radius($value: 4rem) {
  border-top-right-radius: $value;
  border-bottom-right-radius: $value;
  border-bottom-left-radius: $value;
}

@mixin border-radius-reverse($value: 4rem) {
  border-top-left-radius: $value;
  border-bottom-right-radius: $value;
  border-bottom-left-radius: $value;
}

@mixin border-gradient($color, $deg: 90deg) {
  @if $color == red-1 {
    background: var(--orange-drop-shadow-1);
    background: linear-gradient($deg, var(--orange-drop-shadow-1) 0%, var(--gradient-purple-1) 100%);
  } @else if $color == red {
    background: var(--red-1);
    background: linear-gradient($deg, var(--red-1) 0%, var(--red-2) 100%);
  } @else if $color == purple-1 {
    background: var(--gradient-purple-1);
    background: linear-gradient($deg, var(--red-2) 0%, var(--gradient-purple-1) 100%);
  } @else if $color == orange {
    background: var(--gradient-red);
    background: linear-gradient($deg, var(--orange-drop-shadow) 0%, var(--gradient-purple-1) 100%);
  }
}

@mixin gradient-to-white($color: var(--gradient-purple-1), $deg: 90deg) {
  background: $color;
  background: linear-gradient(180deg, $color 0%, rgba(255,255,255,1) 100%);
}

@mixin border-gradient-triple($deg: 90deg, $first-pos: 20%, $second-pos: 50%, $third-pos: 80%) {
  background: var(--gradient-red-rgb);
  background: linear-gradient($deg, var(--gradient-purple-rgba) $first-pos, 
  var(--gradient-red-rgba) $second-pos, var(--gradient-orange-rgba) $third-pos);
}

@mixin icon-gradient() {
  &::before {
    background: var(--gradient-orange);
    background: -webkit-linear-gradient(to top right, var(--gradient-purple) 30%, var(--gradient-red) 50%, var(--gradient-orange) 70%);
    background: -moz-linear-gradient(to top right, var(--gradient-purple) 30%, var(--gradient-red) 50%, var(--gradient-orange) 70%);
    background: linear-gradient(to top right, var(--gradient-purple) 30%, var(--gradient-red) 50%, var(--gradient-orange) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


@mixin drop-shadow($color: var(--blue-rgba-4), $yOffset: 35px, $blurRadius: 25px) {
  filter: drop-shadow(0px $yOffset $blurRadius $color);
  -webkit-filter: drop-shadow(0px $yOffset $blurRadius $color);
  -moz-filter: drop-shadow(0px $yOffset $blurRadius $color);
}