.team {

    &-wrapper {
        position: relative;
        padding-top: 15rem;
    }

    &-inner {
        position: relative;
        z-index: 2;
    }

    .section-heading {
        margin-bottom: 7.9rem;
    }

    &-single {
        
        margin: 0 1rem 3.5rem 1rem;

        &-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            padding: 7.8rem 0 3.5rem 0;

            background-color: #fff;
            @include border-radius(2rem);
        }

        .image {
            max-width: 90%;
            background: #fff;
            border-radius: 50%;
            position: relative;

            padding: .4rem;

            display: flex;
            justify-content: center;
            align-items: center;
    
            &-wrapper {
                position: relative;
                background-color: #fff;
                border-radius: 50%;

                z-index: 2;
    
                padding: 1.4rem;
    
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 991px) {
                    font-size: 1.6rem;
                }
                
            }
      
            @include animateIt;

            &-inner {
                margin: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                transform-origin: center center;
                @include animateIt;


                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border: .1rem solid #fff;
                    position: relative;
                    z-index: 1;
                    border-radius: 50%;
                }

            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                @include border-gradient-triple(45deg);

                opacity: 0;
                visibility: hidden;
                @include animateIt;

                z-index: 1;
            }
        }

        h3 {
            font-size: 2.6rem;
            color: $font-dark;
            margin-top: 3.7rem;
        }

        p {
            color: $font-grey;
        }

        .social {
            margin-top: 3rem;
            li {
                list-style: none;
                display: inline-block;

                a {
                    font-size: 2.4rem;
                    color: $color-grey-1;
                    @include animateIt;
                }

                &:not(:last-child) {
                    margin-right: 1.6rem;
                }
            }

            &-1 {
                li {
                    a {
                        &:hover {
                            color: var(--gradient-orange);
                        }
                    }
                }
            }

            &-2 {
                li {
                    a {
                        &:hover {
                            color: var(--gradient-red);
                        }
                    }
                }
            }

            &-3 {
                li {
                    a {
                        &:hover {
                            color: var(--gradient-purple);
                        }
                    }
                }
            }
        }

        &:hover {
            .image::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &-slider-navigation {
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 100%;
    }

    .background-pattern {
        width: 100%;
        height: 68%;
    }

    @media (max-width: 991px) {

        &-wrapper {
            padding-top: 6rem;
        }

        .section-heading {
            margin-bottom: 3rem;
        }

        &-slider-container {
            max-width: 100%;
            padding: 0;
        }
    }

}